





















































































































import { Component, Mixins, Watch } from 'vue-property-decorator'

import http from '@/http'
import Documents from '@/mixins/Documents'
import Ecp from "@mixins/Ecp";
import { userNamespace } from '@store/user'
import { documentsNamespace } from '@store/documents'
import { DocumentsActionTypes } from '@store/documents/Types'
import {IDocument, IStatusDocument, ITreeCheckboxData} from '@store/documents/Interface'
import './executiveDocuments.scss'


@Component({
  components: {
    'v-form-change': () => import('@/components/forms/FormChange.vue'),
    'v-tree': () => import('./components/Tree.vue'),
    'v-ecp-form-modal': () => import('@/views/library/components/EcpFormModal.vue'),
    'v-ecp-icon': () => import('@/views/library/components/EcpIcon.vue'),
  },
})
export default class ExecutiveDocuments extends Mixins(Documents, Ecp) {
  @userNamespace.State('selectedProject')
  private selectedProject!: number


  @documentsNamespace.State('documentStatus')
  private documentStatus!: IStatusDocument[]

  @documentsNamespace.State('documentsByVolume')
  private documentsByVolume!: IDocument[]

  @documentsNamespace.Action(DocumentsActionTypes.A_FETCH_DOCUMENTS_STATUS)
  private fetchDocumentStatus!: () => void

  @documentsNamespace.Action(DocumentsActionTypes.A_CHANGE_STATUS)
  private changeStatusDocument!: (params) => Promise<void>

  @documentsNamespace.Action(DocumentsActionTypes.A_FETCH_DOCUMENTS_BY_VOLUME)
  private fetchDocuments!: (selectedProject) => void

  @documentsNamespace.Action(DocumentsActionTypes.A_CLEAR_DOCUMENTS_EXECUTIVE)
  private clearDocuments!: () => void

  @userNamespace.Getter('accessedDocumentStatusesForProject')
  private accessedStatuses!: { [key: number]: boolean }

  private selectedStatus: number | null = null

  private checkedDocuments: ITreeCheckboxData = {nodes: [], labels: [], count: 0}

  private checkedDocumentsIds: number[] = []

  private checkedDocumentsCount = 0

  private selectedSatusMassChange = ''

  private showFormChange: boolean = false

  private loading : boolean = true

  private get isDisabled () {
    return this.checkedDocumentsCount === 0
  }

  private get isOwner(){
    return this?.user?.projects?.find(el => el.id === this.selectedProject)?.roleId === 1
  }

  protected async mounted() {
    this.loading = true
    await this.fetchDocumentStatus()
    await this.fetchDocuments({ projectId: this.selectedProject, statusId: this.selectedStatus })
    this.loading = false
  }

  protected destroyed() {
    this.clearDocuments()
  }

  private changeDocumentation(_doc) {
    this.showFormChange = true
  }

  private handleCheck (payload) {
    this.checkedDocuments = payload
    this.checkedDocumentsIds = this.checkedDocuments.nodes.reduce((sum: number[], el:any) => {
      el.type === 'document' && sum.push(el.id)
      return sum
    }, [])
    this.checkedDocumentsCount = payload.count
  }


  private handleSatusMassChange (value) {
    this.$confirm('Вы действительно хотите изменить стусы выбранных документов?', 'Внимание!', {
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет',
      type: 'warning',
    }).then(() => {
      const params = {
        document_ids: this.checkedDocuments.labels,
        status_id: value,
      }
      this.changeStatusDocument(params).then(()=>{
        this.fetchDocuments({ projectId: this.selectedProject })
        this.$successNotify()
        this.selectedSatusMassChange = ''
      })
    })
  }

  private downloadDocuments () {
    if (!this.isDisabled) {
      this.loading = true
      http.post('/documents/mass-download', { documents: this.checkedDocumentsIds })
        .then(r => {
          this.massDownloadDocuments(r.data.link)
        })
        .catch(error => this.$errorNotify(error.response?.data?.message))
        .finally(() => this.loading = false)
    }
  }

  @Watch('selectedStatus')
  private async changeSelectedStatus(newValue) {
    this.loading = true

    this.handleCheck({nodes:[], labels: [], count:0})

    if (newValue) {
      await this.fetchDocuments({ projectId: this.selectedProject, statusId: newValue })
    } else {
      await this.fetchDocuments({ projectId: this.selectedProject })
    }

    this.loading = false
  }

  get documentsMap(){
    if(!this.documentsByVolume || !Array.isArray(this.documentsByVolume) || this.documentsByVolume.length === 0) return {}
    const map = {}
    let index = 0
    let targets: any = [this.documentsByVolume]

    while(index < targets.length){
      targets[index].forEach(el => {
        if(el.type === 'tom') targets.push(el.content)
        if(el.type === 'document') map[el.id] = el
      })
      index+=1
    }


    return map
  }

  private massEcp(){
    this.documentsForSigns = this.checkedDocumentsIds.map(id => this.documentsMap[id])
    this.openEcpModal()
  }
}
